import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": true,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "dark",
  "types": ["website", "frontend", "backend"],
  "status": ["completed"],
  "title": "Virtual Whiteboard",
  "subtitle": "A MERN Project",
  "description": "",
  "years": ["2021"],
  "institutions": ["nb"],
  "location": "cph",
  "keywords": {
    "topics": ["whiteboard"],
    "tools": ["react", "gatsby", "html5", "css3", "nodeJS", "express", "mongoDB", "jwt", "fabricJS", "bootstrap"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer & Developer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # Virtual Whiteboard */
    }
    <h1>{`Virtual Whiteboard`}</h1>
    <h2>{`Description`}</h2>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Screenshot`}</li>
      <li parentName="ul">{`Application`}</li>
      <li parentName="ul">{`Backend GitHub`}</li>
      <li parentName="ul">{`Frontend GitHub`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      